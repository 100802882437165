import CategoryText from '@/components/categories/category-text';
import MidBanner from '@/components/categories/mid-banner';
import PopularSofa from '@/components/categories/popular-sofa-set';
import HocHead from '@/components/HOC/HocHead';
import Banner from '@/components/home/banner';
import BeautifyLivingSpaces from '@/components/home/beautify-living-spaces';
import HappyWords from '@/components/home/happy-words';
import BannerMobile from '@/components/home/mobile/banner-mobile';
import BeautifyLivingSpacesMobile from '@/components/home/mobile/beautify-living-spaces';
import FinestBrandMobile from '@/components/home/mobile/finest-brand-mobile';
import HappyWordsMobile from '@/components/home/mobile/happy-words-mobile';
import Recommendedmob from '@/components/home/mobile/recommended';
import StoresMobile from '@/components/home/mobile/stores-mobile';
import News from '@/components/home/News';
import Recommended from '@/components/home/recommended';
import ShopNow from '@/components/home/shop-now';
import Stores from '@/components/home/stores';
import CategoryDescription from '@/components/infomation/category-description';
import FinestBrand from '@/components/infomation/finest-brand';
import LargeDecorating from '@/components/infomation/large-decorating';
import CategoryDescriptionMobile from '@/components/infomation/mobile/category-description';
import LargeDecoratingMobile from '@/components/infomation/mobile/large-decorating';
import SeatingEssentialMobile from '@/components/infomation/mobile/seating-essential';
import Offer from '@/components/infomation/Offer';
import SeatingEssential from '@/components/infomation/seating-essential';
import Layout from '@/layout/Layout';
import { NextPageWithLayout } from '@/types/NextPageWithLayout';
import { homePage, homePageMobile } from '@/utils/generateDefultLocationPage';
import { generateHomeSchema } from '@/utils/schema';
import { GetServerSideProps } from 'next';
import React, { ReactElement } from 'react';
import styles from './style.module.scss';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const userAgent = ctx.req?.headers['user-agent'] || navigator.userAgent;
  const isMobile = /mobile|tablet|iPad|Android/i.test(userAgent);
  return {
    props: {
      isMobile,
    },
  };
};

interface HomePageProps {
  isMobile: boolean;
}

const Home: NextPageWithLayout<HomePageProps> = ({ isMobile }) => {
  const jsonLdData = generateHomeSchema();
  const hocHeadData = {
    jsonLdData: {
      home: null,
    },
  };

  const componentMap: any = {
    banner: Banner,
    emi: MidBanner,
    'category-description': CategoryDescription,
    'popular-picks': PopularSofa,
    offer: Offer,
    'beautify-living-space': BeautifyLivingSpaces,
    'set-budget': LargeDecorating,
    'seating-essential': SeatingEssential,
    'media-partners': News,
    'customer-reviews': HappyWords,
    'shop-now': ShopNow,
    'experience-stores': Stores,
    'explore-more': Recommended,
    'finest-brand': FinestBrand,
    'category-info': CategoryText,
  };

  const componentMapMobile: any = {
    banner: BannerMobile,
    'category-description': CategoryDescriptionMobile,
    'popular-picks': PopularSofa,
    'seating-essential': SeatingEssentialMobile,
    offer: Offer,
    'shop-now': ShopNow,
    'set-budget': LargeDecoratingMobile,
    'explore-more': Recommendedmob,
    'finest-brand': FinestBrandMobile,
    'beautify-living-space': BeautifyLivingSpacesMobile,
    'experience-stores': StoresMobile,
    'media-partners': News,
    'customer-reviews': HappyWordsMobile,
  };
  const currentData = isMobile ? homePageMobile() : homePage();
  const currentComponentMap = isMobile ? componentMapMobile : componentMap;
  return (
    <>
      <HocHead seo={hocHeadData} />
      <div className={styles['home-wrapper']}>
        {currentData.map((section: any, index: number) => {
          const Component = currentComponentMap[section.component];
          if (Component) {
            return (
              <Component
                key={index}
                items={section.section}
                isMobile={isMobile}
              />
            );
          }
          return null;
        })}
      </div>
      {jsonLdData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: jsonLdData,
          }}
        />
      )}
    </>
  );
};

Home.getLayout = function getLayout(page: ReactElement, data: any) {
  return <Layout data={data}>{page}</Layout>;
};

export default Home;

import React from 'react';
import styles from './style.module.scss';
import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';

interface ShopNowProps {
  items: any;
}

const ShopNow: React.FC<ShopNowProps> = ({ items }) => {
  return (
    <section className={styles.mettresssection}>
      <div className="home-container-1600">
        <div className={styles.mettressinner}>
          {items.items.map((item: any, index: number) => (
            <CustomLink
              href={item.href}
              key={index}
              className={`${styles['mettressimg']} ${styles['mettressimg-off']}`}
            >
              <WsStaticImage
                src={item.image}
                alt={item.alt}
                title={item?.title}
                width={895}
                height={410}
                quality={75}
              />
            </CustomLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ShopNow;

import WsStaticImage from '@/components/common/WsStaticImage';
import { parseHtml } from '@/utils/common';
import React from 'react';
import styles from './style.module.scss';
import CustomLink from '@/components/CustomLink';

interface StoresMobileProps {
  items: any;
}
const chunkArray = (arr: any[], chunkSize: number) => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};
const StoresMobile: React.FC<StoresMobileProps> = ({ items }) => {
  const chunks = chunkArray(items?.items, 4);
  return (
    <>
      <section className={styles.storemobsection}>
        <div className={styles.storemobinner}>
          <div className="wdRow ">
            <div className="wdCol-lg-12 ">
              <div className={styles.storemobheading}>
                <div className={styles.storemobtitle}>{items?.title}</div>
                <div className={styles.storesoffer}>
                  <div className={styles.stores}>
                    <p className={styles['offerPrice']}>Offer</p>
                  </div>
                  <div className={styles.storesright}>
                    {parseHtml(items.description)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.storemobcontent}>
            {chunks.map((chunk, rowIndex) => (
              <div className={styles.storeRow} key={rowIndex}>
                {chunk.map((store: any, index: number) => (
                  <CustomLink
                    href={store.href}
                    className={styles.storecard}
                    key={index}
                  >
                    <div className={styles.storeimg}>
                      <figure>
                        <WsStaticImage
                          src={store.image}
                          alt={store.alt}
                          width={445}
                          height={296}
                          quality={75}
                          //loading="lazy"
                        />
                      </figure>
                      <div className={styles.storetext}>
                        <p className={styles.title}>{store.title}</p>
                        <p>{store.label}</p>
                      </div>
                    </div>
                  </CustomLink>
                ))}
              </div>
            ))}
          </div>
          <div className="wdRow">
            <div className="wdCol-lg-12">
              <div className={styles.allstoresbtn}>
                <CustomLink
                  href={items?.viewMore}
                  className="btn btn-primary btn-find-store"
                  scroll={false}
                >
                  Explore All Nearest Stores
                </CustomLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StoresMobile;

import React from 'react';
import styles from './style.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';

interface BeautifyLivingSpacesProps {
  items: any;
}
const BeautifyLivingSpaces: React.FC<BeautifyLivingSpacesProps> = ({
  items,
}) => {
  return (
    <>
      <section className={styles.livingsection}>
        <div className="container-1600">
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.livingheading}>
                <div className={styles.headingnew}>{items?.title}</div>
                <p>{items?.description}</p>
              </div>
            </div>
          </div>
          <div className={styles.livingcontent}>
            <div className="sellerslider beautify-living-slider">
              <Swiper
                slidesPerView={1}
                spaceBetween={12}
                navigation={true}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 12,
                  },
                }}
                modules={[Navigation]}
                className="mySwiper"
              >
                {items?.items?.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <CustomLink
                      href={item.href}
                      className={styles.livingcard}
                      tabIndex={-1}
                    >
                      <div className={styles.livingimg}>
                        <WsStaticImage
                          src={item.image}
                          alt={item.alt}
                          title={item.title}
                          //// loading="lazy"
                          quality={100}
                          width={445}
                          height={425}
                        />
                      </div>
                      <div className={styles.livingtext}>
                        <div className={styles.livingoffer}>{item.off}</div>
                        <div className={styles.livingname}>{item.title}</div>
                        <p>{item.description}</p>
                        <span className={styles.livinglink}>{item.label}</span>
                      </div>
                    </CustomLink>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BeautifyLivingSpaces;

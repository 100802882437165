import { addCoordinatesToImagePath } from '@/utils/common';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import CustomLink from '@/components/CustomLink';
interface RecommendedProps {
  items: any;
}
const imageBaseUrl = process.env.NEXT_PUBLIC_IMAGE_BASE_URL;
const Recommended: React.FC<RecommendedProps> = ({
  items: recommendedData,
}) => {
  const [last, setLastData] = useState<any>();
  const [recentData, setRecentData] = useState<any[]>([]);

  useEffect(() => {
    if (localStorage.categoryAndProduct) {
      const visitedItems: any[] = JSON.parse(localStorage.categoryAndProduct);

      if (visitedItems.length > 1) {
        const data: any = recommendedData?.items ?? [];
        visitedItems.forEach((item) => {
          item.imgSrc = `${imageBaseUrl}${addCoordinatesToImagePath(item.imgSrc, 750, 650)}`;
          data.unshift(item);
        });

        const last = data.shift();
        setLastData(last);

        let recentItems = data.splice(0, 4);

        recentItems = recentItems.map((item: any) =>
          item?.title === '' &&
          item?.href === '' &&
          item?.image === '' &&
          item?.alt === ''
            ? last
            : item
        );

        while (recentItems.length < 4) {
          recentItems.push(last);
        }

        setRecentData(recentItems);
      }
    }
  }, []);

  return (
    <>
      {recentData?.length > 0 && (
        <section className={styles.recommendedsection}>
          <div className="home-container-1600">
            <div className={styles.recommendedinner}>
              <div className="wdRow ">
                <div className="wdCol-lg-12">
                  <div className={styles.recommendedheading}>
                    <p className={styles.heading}>
                      {recommendedData?.title || 'Recommended For You'}
                    </p>
                    <p>{recommendedData.description}</p>
                  </div>
                </div>
              </div>
              <div className={styles.recommendedcontent}>
                <div className="wdRow ">
                  {last && (
                    <div className="wdCol-lg-6 wdCol-md-6 wdCol-sm-12 wdCol-12">
                      <CustomLink
                        href={
                          last.href?.replace(
                            'https://www.woodenstreet.com/',
                            '/'
                          ) ?? '/'
                        }
                        className={`${styles.recomcard} ${styles.recomcardbig}`}
                      >
                        <div
                          className={`${styles.recomimg} ${styles.recomimgbig}`}
                        >
                          <figure>
                            <Image
                              src={last.imgSrc}
                              alt="best furniture online | best online furniture sites in india"
                              width={500}
                              height={500}
                              quality={75}
                              className={styles.imgreco}
                            />
                          </figure>
                        </div>
                        <div className={styles.recomtext}>
                          <p>{last.title}</p>
                        </div>
                      </CustomLink>
                    </div>
                  )}
                  <div className="wdCol-lg-6 wdCol-md-6 wdCol-sm-12 wdCol-12">
                    <div className="wdRow wdgx-md-3 wdgx-2">
                      {recentData?.map((item, index) => (
                        <div
                          className="wdCol-lg-6 wdCol-md-6 wdCol-sm-6 wdCol-6"
                          key={index}
                        >
                          <CustomLink
                            href={item.href}
                            className={styles.recomcard}
                          >
                            <div className={styles.recomimg}>
                              <figure>
                                <Image
                                  width={378}
                                  height={327}
                                  src={item.imgSrc}
                                  alt="best furniture online | best online furniture sites in india"
                                  title={item.title}
                                  quality={75}
                                  className={styles.imgreco}
                                />
                              </figure>
                            </div>
                            <div className={styles.recomtext}>
                              <p>{item.title}</p>
                            </div>
                          </CustomLink>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Recommended;

import React from 'react';
import styles from './style.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Navigation } from 'swiper/modules';
import WsFillImage from '@/components/common/WsFillImage';
import CustomLink from '@/components/CustomLink';

interface BannerProps {
  items: any;
}
const Banner: React.FC<BannerProps> = ({ items }) => {
  return (
    <>
      <section className={styles['banner-section']}>
        <div className="container-1600 p-0">
          <div className={styles['banner-content']}>
            <div className={styles['banner-left']}>
              <div className={styles['banner-slider']}>
                <div className="bannerswiperslider">
                  <Swiper
                    navigation={true}
                    loop={true}
                    modules={[Navigation, Autoplay]}
                    loadPrevNext={true}
                    loadPrevNextAmount={1}
                    lazy={true}
                    className="mySwiper"
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                  >
                    {items?.items?.map((banner: any, index: number) => (
                      <SwiperSlide key={index}>
                        <CustomLink href={banner.href}>
                          <figure>
                            <WsFillImage
                              alt={banner.alt}
                              src={banner.image}
                              title={banner.title}
                              // loading={index == 0 ? 'eager' : 'lazy'}
                              priority={index == 0 ? true : false}
                            />
                          </figure>
                        </CustomLink>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className={styles['banner-right']}>
              <div className="wdRow wdgy-2">
                {items.itemsRight.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`wdCol-lg-12 wdCol-md-12 ${index % 2 === 0 ? 'wdCol-sm-6' : ''}`}
                  >
                    <div className={styles['banner-img']}>
                      <CustomLink href={item.href}>
                        <figure>
                          <WsFillImage
                            src={item.image}
                            alt={item.alt}
                            title={item.title}
                            priority
                            // loading="lazy"
                            // loading="eager"
                            // height={312}
                            // width={624}
                          />
                        </figure>
                      </CustomLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={styles.discountsection}>
        <div className={styles.discountinner}>
          <div className="container-1600 p-0">
            <div className={styles.discountimg}>
              <figure>
                <WsFillImage
                  src={`/images/home-new1/emi-banner.jpg?v=${imgVer}`}
                  alt="Emi Installation"
                  // width={'1600'}
                  // height={'142'}
                  quality={100}
                  sizes="100vw"
                />
              </figure>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Banner;

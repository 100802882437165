import WsStaticImage from '@/components/common/WsStaticImage';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './style.module.scss';

interface SeatingEssentialProps {
  locations: {
    city: string;
    url: string;
    mid1_banner_image: { image: string; alt: string; title: string }[];
    title: string;
  }[];
}

const StoreInCity: React.FC<SeatingEssentialProps> = ({ locations }) => {
  const imageBaseUrl = process.env.NEXT_PUBLIC_IMAGE_BASE_URL;

  return (
    <>
      <section className={styles.shopsofasizesection}>
        <div className={styles.shopsofasizeinner}>
          <div className="container-1240 px-sm-0">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.shopsofasizeheading}>
                  <div className={styles['head-title']}>
                    STORE IN {locations[0]?.city}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.shopsofasizecontant}>
              <div
                className={`${styles.shopbox} ${styles['grid-container-4-col']}`}
              >
                {locations?.map((store: any, index: number) => (
                  <Link
                    href={store.url}
                    className={styles.shopsofasizecard}
                    key={index}
                  >
                    <figure>
                      <Image
                        src={
                          !store?.mid1_banner_image?.[0].image
                            .toLowerCase()
                            .includes('opening')
                            ? `${imageBaseUrl?.replace('cache/', '')}${store?.mid1_banner_image?.[0].image}` // ? `${imageBaseUrl}${addCoordinatesToImagePath(store?.mid1_banner_image?.[0].image, 810, 702)}`
                            : '/images/store/fur-store-17.jpg'
                        }
                        alt={
                          store?.mid1_banner_image?.[0].alt ||
                          store?.mid1_banner_image?.[0].title
                        }
                        quality={75}
                        width={694}
                        height={196}
                        // loading={'eager'}
                      />
                    </figure>

                    <p className={styles['col-name']}>{store.title}</p>

                    <Link href={store.url} className={styles['price']}>
                      View Store
                    </Link>

                    {/* {collection.label ? (
                      typeof collection.label === 'object' ? (
                        <>
                          <p className={styles['col-name']}>
                            {collection.title}
                          </p>
                          <p
                            className={styles['price']}
                            style={{ textAlign: 'left' }}
                          >
                            {collection.label.price && (
                              <>
                                {collection.label.price}{' '}
                                <del className={styles.offprice}>
                                  {collection.label.mrp}
                                </del>
                                <span className={styles.discountoff}>
                                  {collection.label.discount}
                                </span>
                              </>
                            )}
                          </p>
                          {collection.saletag && (
                            <p
                              className={styles.sale}
                              style={{
                                background:
                                  collection.saletag === 'New'
                                    ? '#4caf50'
                                    : '#E27A34',
                              }}
                            >
                              {collection.saletag}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <p
                            className={styles['col-name']}
                            style={{ textAlign: 'center' }}
                          >
                            {collection.title}
                          </p>
                          <p
                            className={styles['price']}
                            style={{ textAlign: 'center' }}
                          >
                            {collection.label}
                          </p>
                        </>
                      )
                    ) : (
                      <p
                        className={styles['col-name']}
                        style={{ textAlign: 'center' }}
                      >
                        {collection.title}
                      </p>
                    )} */}
                  </Link>
                ))}
              </div>
            </div>

            <div className={styles.viewmorebtndiv}>
              <Link
                href="/offline-furniture-store"
                className={`${styles['view-all-furni']} btn view-all-btn`}
              >
                View All Stores
                <WsStaticImage
                  src="/images/home-new1/ArrowRight.svg"
                  alt="ArrowRight"
                  width={24}
                  height={20}
                  quality={75}
                  // loading="lazy"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StoreInCity;

import React from 'react';
import styles from './style.module.scss';
import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';

interface BeautifyLivingSpacesMobileProps {
  items: any;
  // items: React.ReactNode[];
}
const BeautifyLivingSpacesMobile: React.FC<BeautifyLivingSpacesMobileProps> = ({
  items,
}) => {
  return (
    <>
      <section className={styles.livingmobileinner}>
        <div className="wdRow ">
          <div className="wdCol-lg-12">
            <div className={styles.livingmobileheading}>
              <p className={styles['title']}>{items?.title}</p>
              <p className={styles['sub-title']}>{items?.description}</p>
            </div>
          </div>
        </div>
        <div className="wdRow wdgx-2">
          {items?.items?.map((item: any, index: number) => (
            <div className="wdCol-sm-6 wdCol-6 p-0" key={index}>
              <CustomLink
                href={item.href}
                className={styles.livingmobilecard}
                tabIndex={-1}
              >
                <WsStaticImage
                  src={item.image}
                  alt={item.alt ?? 'poster'}
                  title={item.title}
                  //loading="lazy"
                  quality={100}
                  width={445}
                  height={425}
                  sizes="100vw"
                  key={index}
                />
                <div className={styles.livingtext}>
                  <p className={styles['category-offer']}>{item.off}</p>
                  <p className={styles['category-name']}>{item.title}</p>
                  <p className={styles['category-text']}>{item.label}</p>
                </div>
              </CustomLink>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default BeautifyLivingSpacesMobile;

import React from 'react';
import styles from './style.module.scss';
import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
// import { parseHtml } from '@/utils/common';

interface StoresProps {
  items: any;
}

const Stores: React.FC<StoresProps> = ({ items }) => {
  return (
    <>
      <section className={styles.storesection} style={items?.style}>
        <div className="container-fluid">
          <div className={styles.storeinner}>
            <div className={styles.storecontent}>
              <div className={styles['store-left']}>
                <p className={styles['heading']}>
                  100+
                  <span> Experience Stores Across India</span>
                </p>
                <p className={styles['offer-box']}>
                  <span> Get Extra Upto</span>
                  <strong className={styles['strong']}>10% Off</strong>
                  <small className={styles['small']}>
                    On Shopping from Your Nearest Stores
                  </small>
                </p>
                <div className={styles.allstoresbtn}>
                  <CustomLink
                    href={items?.viewMore}
                    className={`${styles['btn-primary']} btn btn-primary`}
                  >
                    Explore All Stores
                  </CustomLink>
                </div>
              </div>
              <div className={styles['store-right']}>
                <div className="wdRow wdgx-3 wdRow-cols-1 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-4">
                  {items?.items?.map((store: any, index: number) => (
                    <div className={`${styles['col-px']} wdCol`} key={index}>
                      <CustomLink
                        href={store.href}
                        className={styles.storecard}
                      >
                        <div className={styles.storeimg}>
                          <figure>
                            <WsStaticImage
                              src={store.image}
                              alt={store.alt}
                              width={445}
                              height={296}
                              quality={75}
                            />
                          </figure>
                          <div className={styles.storetext}>
                            <div className={styles.storename}>
                              {store.title}
                            </div>
                            <p>{store.label}</p>
                          </div>
                        </div>
                      </CustomLink>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stores;

import React from 'react';
import styles from './style.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import { Pagination } from 'swiper/modules';
import DeliveryMobile from '../delivery-mobile';
import TopPicksMobile from '../top-picks-mobile';

interface BannerMobileProps {
  items?: any;
}
const BannerMobile: React.FC<BannerMobileProps> = ({ items }) => {
  return (
    <>
      <TopPicksMobile />
      <div className="anniva-mobile-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          lazy={true}
          loadPrevNext={true}
          loadPrevNextAmount={1}
        >
          {items?.items?.map((banner: any, i: number) => (
            <SwiperSlide key={i}>
              <CustomLink
                href={banner.href}
                className={styles.storeimg}
                prefetch={true}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.location.href = banner.href;
                }}
              >
                <figure>
                  <WsStaticImage
                    alt={banner.alt}
                    src={banner.image}
                    title={banner.title}
                    width={430}
                    height={262}
                    priority={i == 0 ? true : false}
                  />
                </figure>
              </CustomLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* </section> */}
      <section className={styles.discountmobilesection}>
        <div className={styles.discountinner}>
          <ul className={styles.discounslider}>
            <li className={styles.discountimg}>
              <WsStaticImage
                src={'/images/home-new1/no-cost-emi.png'}
                alt="Emi Installation"
                width={596}
                height={132}
                quality={100}
                //loading="eager"
              />
            </li>
            <li className={styles.discountimg}>
              <WsStaticImage
                src={'/images/home-new1/store-strip.png'}
                alt="Emi Installation"
                width={596}
                height={132}
                quality={100}
                //loading="eager"
              />
            </li>
            <li className={styles.discountimg}>
              <WsStaticImage
                src={'/images/home-new1/free-delivery-strip.png'}
                alt="Emi Installation"
                width={596}
                height={132}
                quality={100}
                //loading="eager"
              />
            </li>
          </ul>
        </div>
      </section>
      <DeliveryMobile />
    </>
  );
};

export default BannerMobile;

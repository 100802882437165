import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';

interface OffersMobileProps {
  // data: React.ReactNode[];
}

const OffersMobile: React.FC<OffersMobileProps> = () => {
  const postersData = [
    {
      href: 'https://play.google.com/store/apps/details?id=com.woodenstreet&pli=1',
      ariaLabel: 'Download The App',
      imgSrc: '/mobile/images-m/category-new/android-banner.jpg',
      alt: 'Download The App ',
      title: 'Download The App',
    },
    {
      href: 'https://itunes.apple.com/in/app/wooden-street/id1162068644?ls=1&amp;mt=8',
      ariaLabel: 'Download The App',
      imgSrc: '/mobile/images-m/category-new/ios-banner.jpg',
      alt: 'Download The App ',
      title: 'Download The App',
    },
  ];
  const [type, setType] = useState<any[]>([]);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) setType([postersData[0]]);
    else if (/iPad|iPhone|iPod/.test(userAgent)) setType([postersData[1]]);
  }, []);

  return (
    <>
      <section className={styles.offerssection}>
        <div className={styles.offersinner}>
          <div className="wdRow">
            {type?.map((poster, index) => (
              <div className="wdCol-lg-12" key={index}>
                <Link
                  href={poster.href}
                  className={styles.outdoorcard}
                  aria-label={poster.ariaLabel}
                >
                  <div className={styles.outdoorimg}>
                    <WsStaticImage
                      src={poster.imgSrc}
                      alt={poster.alt ?? 'poster'}
                      title={poster.title}
                      width={585}
                      height={508}
                      quality={75}
                      // loading="lazy"
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OffersMobile;
